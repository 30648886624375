'use client';

import { Popover } from '@headlessui/react';
import { UseCustomerDataResult } from '@lib/machine-parts/storefront/account/data-access';
import { useAuthenticationContext } from '@lib/machine-parts/storefront/account/feature';
import { HeaderSanityData } from '@lib/machine-parts/storefront/layout/data-access/server';
import { AccountIcon, ButtonVariant } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { formatTranslation } from '@lib/machine-parts/storefront/utils';

import { LoginStatusMenu } from './LoginStatusMenu';

export interface LoginStatusProps {
    customer?: UseCustomerDataResult;
    translation?: HeaderSanityData['login_status'];
}

export function LoginStatus({ translation }: LoginStatusProps) {
    const { loggedIn, customerData } = useAuthenticationContext();
    return loggedIn ? <LoggedIn customer={customerData} translation={translation} /> : LoggedOut();
}

function LoggedIn({ customer, translation }: Pick<LoginStatusProps, 'customer' | 'translation'>) {
    const t = formatTranslation(translation);
    const context = { firstName: customer?.firstName ?? '' };

    return (
        <Popover>
            <Popover.Button
                className="flex flex-row justify-between items-center gap-x-2 border-0 focus:outline-none"
                data-testid="account-dropdown"
            >
                <span className="hidden sm:flex">{t('username', { context })}</span>
                <AccountIcon />
            </Popover.Button>
            <Popover.Panel className="ml-4 mr-4">
                {({ close }) => <LoginStatusMenu close={close} context={context} translation={translation?.menu} />}
            </Popover.Panel>
        </Popover>
    );
}

function LoggedOut() {
    return (
        <Link href="/account/login" variant={ButtonVariant.None}>
            <AccountIcon data-testid="account-icon-blanc" />
        </Link>
    );
}
