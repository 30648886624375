'use client';

import MegaMenu from './MegaMenu';
import { HeaderTop, HeaderTopProps } from './HeaderTop';
import { Category } from '@lib/machine-parts/storefront/layout/data-access/server';

export function catMap({
    title,
    slug,
    image,
    subcategories,
}: Category & {
    subcategories?: Category[];
    parent?: string;
}): any {
    return {
        id: slug,
        name: title,
        image,
        href: `/categories/${slug}`,
        categoryType: slug === 'all-products' ? '*' : `tag:'pc:${slug}'`,
        subcategories: subcategories?.map((item) => catMap({ ...item, parent: slug })),
    };
}

export function Navigation(headerTopProps: HeaderTopProps) {
    const categories = headerTopProps.translation?.categories?.map(catMap) ?? [];

    return (
        <nav aria-label="Top">
            <div className="bg-white">
                <HeaderTop {...headerTopProps} />
                <MegaMenu navigation={{ categories }} />
            </div>
        </nav>
    );
}
