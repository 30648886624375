'use client';

import { useCallback } from 'react';

import { CartLine as CartLineUI, DesignType } from '@lib/machine-parts/storefront/checkout/ui';
import { useMixpanel, useMoneyWithCurrency } from '@lib/machine-parts/storefront/utils';
import { useCart, useCartLine } from '@shopify/hydrogen-react';

interface CartLineProps {
    itemNoFormatter?: (price: string) => string;
    unitFormatter?: (price: string) => string;
    totalFormatter?: (price: string) => string;
    designType?: DesignType;
    disableDeletion?: boolean;
    onCartNavigate?: () => void;
}

export function CartLine({
    itemNoFormatter,
    unitFormatter,
    totalFormatter,
    designType = DesignType.cart,
    onCartNavigate = () => null,
    disableDeletion = false,
}: CartLineProps) {
    const { lines, linesUpdate } = useCart();
    const { merchandise, quantity } = useCartLine();
    const title = merchandise?.product?.title ?? merchandise?.title;
    const compareAtPrice = merchandise?.compareAtPrice ? merchandise?.compareAtPrice : undefined;
    const mixpanel = useMixpanel();
    const onCartEdit = useCallback(
        (type: 'increased' | 'decreased' | 'removed' | 'type number of items', amount?: number) => () => {
            mixpanel.track(`Cart Edited - ${type}${amount ? `: ${amount}` : ''}`, {
                product: {
                    title,
                    price: merchandise?.price,
                    compareAtPrice: compareAtPrice,
                    quantityAvailable: merchandise?.quantityAvailable,
                },
            });
        },
        [mixpanel, title, merchandise?.price, merchandise?.quantityAvailable, compareAtPrice],
    );
    const productUnitPrice = useMoneyWithCurrency({
        currencyCode: merchandise?.price?.currencyCode ?? 'EUR',
        amount: merchandise?.price?.amount ?? '0',
    });
    const productTotalPrice = useMoneyWithCurrency({
        currencyCode: merchandise?.price?.currencyCode ?? 'EUR',
        amount: `${parseFloat(merchandise?.price?.amount ?? '0') * (quantity ?? 1)}`,
    });

    if (!merchandise?.price || !merchandise?.product?.handle || !title) return null;

    return (
        <CartLineUI
            designType={designType}
            disableDeletion={disableDeletion}
            itemNo={itemNoFormatter?.(merchandise.product.handle) ?? merchandise.product.handle}
            productLink={`/products/${merchandise.product.handle.toUpperCase()}`}
            productImageUrl={merchandise?.image?.url}
            productSKU={merchandise.product.handle} // prefix it here using sanity
            productTitle={title}
            productUnitPrice={unitFormatter?.(productUnitPrice) ?? productUnitPrice}
            productTotalPrice={totalFormatter?.(productTotalPrice) ?? productTotalPrice}
            quantity={quantity}
            merchandise={merchandise}
            lines={lines}
            onNavigate={onCartNavigate}
            onIncrease={onCartEdit('increased')}
            onDecrease={onCartEdit('decreased')}
            onRemove={onCartEdit('removed')}
            onInputType={(amount) => onCartEdit('type number of items', amount)}
            linesUpdate={linesUpdate}
        />
    );
}
