'use client';

import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ButtonVariant } from '@lib/machine-parts/storefront/ui';
import { MixpanelTrack } from '@lib/machine-parts/storefront/utils';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';

import { useTranslation } from '@lib/machine-parts/storefront/utils';
import { HeaderTopProps } from './HeaderTop';
import { catMap } from './Navigation';

export function MobileNavigation({
    isOpen,
    setIsOpen,
    ...headerTopProps
}: {
    isOpen?: boolean;
    setIsOpen: (v: boolean) => void;
} & Pick<HeaderTopProps, 'translation'>) {
    const { t } = useTranslation(['translation', 'categories']);
    const navigation = headerTopProps.translation?.categories?.map(catMap) ?? [];

    return (
        <Dialog as="div" className="fixed inset-0 flex z-40 xl:hidden" open={isOpen} onClose={setIsOpen}>
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
            <div className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                <div className="px-4 pt-5 pb-2 flex">
                    <MixpanelTrack eventName="'Mobile Navigation Close Menu Button'">
                        <button
                            type="button"
                            className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-brand-black"
                            onClick={() => setIsOpen(false)}
                        >
                            <span className="sr-only">{t('mobile-nac-close', 'Close menu')}</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </MixpanelTrack>
                </div>

                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                    {navigation.map((category) => (
                        <div key={category.name} className="flow-root">
                            <Link
                                href={category.href}
                                onClick={() => setIsOpen(false)}
                                className="-m-2 p-2 block font-medium text-gray-900"
                                mixpanel={{ eventName: `Mobile Navigation ${category.name} Button` }}
                                variant={ButtonVariant.None}
                            >
                                {category.name}
                            </Link>
                        </div>
                    ))}
                </div>

                <div className="border-t border-gray-200 py-6 px-4 space-y-6">
                    <div className="flow-root">
                        <Link
                            href="/contact"
                            onClick={() => setIsOpen(false)}
                            className="-m-2 p-2 block font-medium text-gray-900"
                            mixpanel={{ eventName: `Mobile Navigation Contact Button` }}
                            variant={ButtonVariant.None}
                        >
                            {t('mobile-nav-contact', 'Contact')}
                        </Link>
                    </div>
                    <div className="flow-root">
                        <Link
                            href="/account/settings"
                            onClick={() => setIsOpen(false)}
                            className="-m-2 p-2 block font-medium text-gray-900"
                            mixpanel={{ eventName: `Mobile Navigation My Account Button` }}
                            variant={ButtonVariant.None}
                        >
                            {t('mobile-nav-account', 'My Account')}
                        </Link>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
