import { useQuery } from '@tanstack/react-query';

type GetUserCountryResponse = {
    ip: string;
    network: string;
    version: string;
    city: string;
    region: string;
    region_code: string;
    country: string;
    country_name: string;
    country_code: string;
    country_code_iso3: string;
    country_capital: string;
    country_tld: string;
    continent_code: string;
    in_eu: boolean;
    postal: string;
    latitude: number;
    longitude: number;
    timezone: string;
    utc_offset: string;
    country_calling_code: string;
    currency: string;
    currency_name: string;
    languages: string;
    country_area: number;
    country_population: number;
    asn: string;
    org: string;
};

export async function getIpGeolocation(options?: { ip?: string }) {
    let response: Response;

    if (options?.ip) response = await fetch(`https://ipapi.co/${options.ip}/json/`);
    else response = await fetch('https://ipapi.co/json/');

    return (await response.json()) as GetUserCountryResponse;
}

export const useUserGeolocation = () => {
    return useQuery(
        ['geolocation-country'],
        async () => {
            return getIpGeolocation();
        },
        { staleTime: 1000 * 60 * 60 },
    );
};
