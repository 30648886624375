'use client';

import { HeaderTopProps } from '../../navigation/HeaderTop';
import { MobileNavigation } from '../../navigation/MobileNavigation';
import { Navigation } from '../../navigation/Navigation';
import { useState } from 'react';

export default function Header(headerTopProps: Pick<HeaderTopProps, 'translation'>) {
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    return (
        <div className="bg-white">
            <MobileNavigation {...headerTopProps} isOpen={isMobileNavOpen} setIsOpen={setIsMobileNavOpen} />

            <header className="fixed top-0 w-full z-20">
                <Navigation
                    {...headerTopProps}
                    isMobileNavOpen={isMobileNavOpen}
                    setIsMobileNavOpen={setIsMobileNavOpen}
                />
            </header>
        </div>
    );
}
