'use client';

import { ButtonVariant, FooterBgIcon, LogoIcon } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';

import { footer } from '../../navigation/Navigation.model';
import { useTranslation } from '@lib/machine-parts/storefront/utils';

export default function Footer() {
    const { t, i18n } = useTranslation();

    return (
        <footer aria-labelledby="footer-heading">
            <div className="bg-brand-black">
                <div className="grid xl:grid-cols-2 relative max-w-7xl mx-auto text-white">
                    <div className="hidden xl:block object-cover absolute h-full z-10 -ml-8 pointer-events-none">
                        <FooterBgIcon />
                    </div>
                    <div className="xl:col-span-1 py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                        <div className="font-normal text-sm mb-6 uppercase">
                            {t('footer-quick-links', 'QUICK LINKS')}
                        </div>
                        <div className="space-y-10">
                            {footer(i18n).quickLinks.map((sub) => (
                                <div key={sub.name} className="flow-root">
                                    <Link
                                        href={sub.href}
                                        variant={ButtonVariant.None}
                                        className="-m-2 p-2 block font-heading font-medium text-4xl uppercase"
                                        mixpanel={{ eventName: `Footer QuickLink ${sub.eventName} Button` }}
                                    >
                                        {sub.name}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 font-normal py-12 px-4 sm:px-6 lg:py-16 lg:px-8 ">
                        {footer(i18n).menu.map((menu, menuIdx) => (
                            <div
                                key={menu.name}
                                className={`mb-11 ${menuIdx === 0 ? 'col-span-2' : 'col-span-1'} md:col-span-1`}
                            >
                                <div className="flow-root mb-6">
                                    <div className="-m-2 p-2 block text-sm uppercase">{menu.name}</div>
                                </div>
                                <div>
                                    {menu.menuItems.map((item) => (
                                        <Link
                                            key={item.name}
                                            href={item.href}
                                            variant={ButtonVariant.None}
                                            className="-m-2 p-2 block text-base"
                                            mixpanel={{ eventName: `Footer Menu ${item.eventName} Button` }}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 max-w-7xl items-center mx-auto py-4 px-5 xl:px-0 bg-white">
                <div>
                    <LogoIcon className="w-auto h-7" />
                </div>
                <div className="text-sm md:text-base md:flex md:gap-10 pl-10">
                    <Link
                        href="/privacy-and-cookie-policy"
                        variant={ButtonVariant.None}
                        className="-m-2 p-2 block"
                        mixpanel={{ eventName: 'Footer Privacy & Cookie Policy Button' }}
                    >
                        {t('footer-privacy-cookie-link', 'Privacy & Cookie Policy')}
                    </Link>
                    <Link
                        href="/legal-notice"
                        variant={ButtonVariant.None}
                        className="-m-2 p-2 block"
                        mixpanel={{ eventName: 'Footer Legal Button' }}
                    >
                        {t('footer-legal-notice-link', 'Legal Notice')}
                    </Link>
                    <Link
                        href="/terms-and-conditions"
                        variant={ButtonVariant.None}
                        className="-m-2 p-2 block"
                        mixpanel={{ eventName: 'Footer Terms & Conditions Button' }}
                    >
                        {t('footer-terms-conditions-link', 'Terms & Conditions')}
                    </Link>
                </div>
            </div>
        </footer>
    );
}
