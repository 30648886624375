'use client';

import { Dispatch, SetStateAction, useState } from 'react';
import { ButtonVariant, HeadsetIcon, LogoIcon, SearchIcon } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Dialog } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { LanguageSelector } from '@lib/machine-parts/storefront/layout/feature';
import { SearchBar } from '../molecules/SearchBar';
import { PartPictureModalButton } from '../molecules/modal/PartPictureModalButton';
import { CartToggle } from '../molecules/cart/CartToggle';
import { LoginStatus } from '../../atom/login-status/LoginStatus';
import { HeaderSanityData } from '@lib/machine-parts/storefront/layout/data-access/server';
import { useTranslation } from '@lib/machine-parts/storefront/utils';

export type HeaderTopProps = {
    isMobileNavOpen: boolean;
    setIsMobileNavOpen: Dispatch<SetStateAction<boolean>>;
    translation?: HeaderSanityData;
};

export function HeaderTop({ isMobileNavOpen, setIsMobileNavOpen, translation }: HeaderTopProps) {
    const [mobileSearchbar, setMobileSearchbar] = useState(false);
    const { t } = useTranslation();

    return (
        <div className={'flex flex-col'} data-testid={'header-top'}>
            <div className={`w-full h-16 flex items-center`}>
                <button
                    type="button"
                    className="rounded-md mx-4 sm:mx-6 lg:hidden w-auto "
                    onClick={() => setIsMobileNavOpen(true)}
                >
                    <span className="sr-only">{t('nav-open-menu', 'Open menu')}</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                <div className={`2xl:mx-20 lg:mx-16 flex w-auto `}>
                    <Link
                        variant={ButtonVariant.None}
                        href={`/`}
                        mixpanel={{ eventName: 'Header Logo Icon Button' }}
                        data-testid="logo-icon"
                    >
                        <LogoIcon className="sm:h-[30.64px] sm:w-[136px] h-[24.9px] w-[100px]" />
                    </Link>
                </div>

                <div className="flex-1 hidden sm:block lg:hidden" />

                <div className={`flex-1 hidden lg:flex`}>
                    <SearchBar variant={'headerSearch'} />
                </div>

                <div
                    className={`2xl:mx-20 lg:mx-16 sm:mx-6 mx-4 flex gap-x-4 sm:gap-x-6 justify-end flex-row items-center w-full sm:w-auto`}
                >
                    <LanguageSelector translation={translation?.region_selector} />
                    <div className="lg:hidden">
                        <button className={'flex items-center'} onClick={() => setMobileSearchbar(!mobileSearchbar)}>
                            <div className={'hidden sm:flex'}>
                                <SearchIcon iconSize={'large'} />
                            </div>
                            <div className={'flex sm:hidden'}>
                                <SearchIcon iconSize={'small'} />
                            </div>
                        </button>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <LoginStatus translation={translation?.login_status} />
                    </div>
                    <div className="hidden lg:block">
                        <Link
                            variant={ButtonVariant.None}
                            mixpanel={{ eventName: 'Header Support Page Icon' }}
                            className="flex items-center"
                            href={`/contact`}
                        >
                            <HeadsetIcon />
                        </Link>
                    </div>
                    <div className="flow-root">
                        <div className="flex items-center">
                            <div className={'hidden sm:flex'}>
                                <CartToggle
                                    cartIconSize="large"
                                    className="flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                    handleClick={() => {
                                        if (isMobileNavOpen) setIsMobileNavOpen(false);
                                    }}
                                />
                            </div>
                            <div className={'flex sm:hidden'}>
                                <CartToggle
                                    cartIconSize="small"
                                    className="flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                    handleClick={() => {
                                        if (isMobileNavOpen) setIsMobileNavOpen(false);
                                    }}
                                    navigateToCart
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog
                open={mobileSearchbar}
                onClose={() => {
                    setTimeout(() => setMobileSearchbar(false), 1);
                }}
            >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                <div className="inline-block w-full h-24 text-left transform bg-white fixed top-16">
                    <div className={'mx-4 sm:mx-6 mt-[1px]'}>
                        <SearchBar variant={'headerSearch'} />
                    </div>
                    <button
                        className={'absolute top-[5px] right-6 sm:right-8'}
                        onClick={() => setMobileSearchbar(false)}
                    >
                        <XIcon className={'h-8'} stroke={'rgba(49, 49, 49, 0.7)'} />
                    </button>
                    <PartPictureModalButton
                        label={t('missing-part-search-by-upload-picture', 'Search by uploading a picture')}
                        className={'mt-4 text-black mx-4 sm:mx-6 h-6'}
                    />
                </div>
            </Dialog>
        </div>
    );
}
