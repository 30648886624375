'use client';

import { useRouter } from 'next/navigation';

import { useAuthenticationContext } from '@lib/machine-parts/storefront/account/feature';
import { useCheckout as useCheckout } from '@lib/machine-parts/storefront/checkout/data-access';
import { HeaderSanityData } from '@lib/machine-parts/storefront/layout/data-access/server';
import { Button, ButtonVariant } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { formatTranslation } from '@lib/machine-parts/storefront/utils';

export interface LoginStatusMenuProps {
    close: () => void;
    context?: { firstName: string };
    translation?: HeaderSanityData['login_status']['menu'];
}

export function LoginStatusMenu({ close, context, translation }: LoginStatusMenuProps) {
    const t = formatTranslation<HeaderSanityData['login_status']['menu']>(translation);
    const { push: navigate, refresh } = useRouter();
    const { logout } = useAuthenticationContext();
    const { clearCheckout } = useCheckout();

    return (
        <div className="absolute w-full bg-white drop-shadow-xl p-4 flex flex-col justify-start items-start inset-x-0 z-10 border-t-2 border-gray-50 translate-y-4 md:max-w-xs md:left-auto md:right-4 lg:right-24">
            <h2 className="uppercase text-lg ml-5 mr-5 mb-2 font-semibold text-brand-brown">
                {t('title', { context })}
            </h2>
            {t('description', { allowUndefined: true, context }) && (
                <span className="ml-5 mb-2">{t('description')}</span>
            )}
            {[
                { children: t('settings'), href: '/account/settings', 'data-testid': 'account-menu-settings' },
                { children: t('orders'), href: '/account/orders', 'data-testid': 'account-menu-orders' },
            ].map((props, index) => (
                <Link
                    key={index}
                    variant={ButtonVariant.Menu}
                    {...props}
                    onClick={() => close()}
                    icon={{ size: 0.7 }}
                />
            ))}
            <Button
                data-testid="logout"
                mixpanel={{ eventName: 'Login Status - Log Out' }}
                variant={ButtonVariant.Menu}
                onClick={() => {
                    logout({
                        onSuccess: () => {
                            navigate('/?logout=true');
                            refresh();
                            clearCheckout();
                        },
                    });
                    close();
                }}
                icon={{ size: 0.7 }}
            >
                {t('log_out')}
            </Button>
        </div>
    );
}

export default LoginStatusMenu;
