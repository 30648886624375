'use client';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Popover } from '@headlessui/react';
import { ButtonVariant, ImageWithFallback } from '@lib/machine-parts/storefront/ui';
import { MixpanelTrack, useMixpanel, useTranslation } from '@lib/machine-parts/storefront/utils';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';
import { NavigationModel } from './Navigation.model';

export default function MegaMenu({ navigation }: { navigation: NavigationModel }) {
    const { t } = useTranslation(['categories', 'translation']);
    const mixpanel = useMixpanel();

    return (
        <Popover.Group className="space-y-5 h-full">
            <div className="h-16 hidden lg:flex items-center px-6 lg:px-16 2xl:px-20 gap-10 xl:gap-12">
                {navigation.categories.map((category) =>
                    category.subcategories === undefined ? (
                        <Link
                            key={`menu-${category.name}`}
                            href={category.href}
                            variant={ButtonVariant.Menu}
                            className={`!px-0 relative flex items-center transition-colors ease-out duration-200 text-base font-normal ${
                                category.id === 'special' ? 'text-brand-brown' : 'text-gray-700 hover:text-gray-800'
                            }`}
                            mixpanel={{ eventName: `Mega Menu ${category.name} Button` }}
                        >
                            {category.name}
                        </Link>
                    ) : (
                        <Popover key={`mega-menu-${category.name}`} className="flex">
                            {({ open }) => {
                                return (
                                    <>
                                        <div className="flex">
                                            <Popover.Button
                                                data-testid={`mega-menu-${category.name?.toLowerCase()}-button`}
                                                className={`${
                                                    open
                                                        ? 'border-brand-black text-brand-black bg-gray-50'
                                                        : 'border-transparent text-gray-700 hover:text-gray-800'
                                                }
                                            relative flex items-center transition-colors ease-out duration-200 text-base font-normal border-b-2 -mb-px`}
                                            >
                                                <MixpanelTrack
                                                    eventName={'Main category dropdown menu'}
                                                    properties={{ name: category.name, id: category.id }}
                                                    className={'bg-brand-brown'}
                                                >
                                                    {category.name}
                                                </MixpanelTrack>
                                            </Popover.Button>
                                        </div>

                                        <Popover.Panel className="absolute top-full z-50 inset-x-0 text-gray-500 sm:text-sm">
                                            {({ close }) => (
                                                <>
                                                    {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                                    <div
                                                        className="absolute inset-0 top-1/2 bg-white shadow"
                                                        aria-hidden="true"
                                                    />

                                                    <div className="relative bg-white">
                                                        <div className="mx-auto px-8 pb-8">
                                                            <div className="grid grid-cols-1 items-start gap-y-10 gap-x-8 pt-10 pb-12">
                                                                <div className={`grid grid-cols-5 gap-y-10 gap-x-8`}>
                                                                    {category.subcategories &&
                                                                        category.subcategories.map((subcategory) => (
                                                                            <div key={`mega-menu-${subcategory.name}`}>
                                                                                {/* Category image */}
                                                                                <Link
                                                                                    href={subcategory.href}
                                                                                    variant={ButtonVariant.None}
                                                                                    onClick={() => close()}
                                                                                    mixpanel={{
                                                                                        eventName: `Mega Menu ${subcategory.name} Image`,
                                                                                        properties: {
                                                                                            id: subcategory.id,
                                                                                            name: subcategory.name,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    <ImageWithFallback
                                                                                        src={subcategory.image}
                                                                                        alt={`Category image ${subcategory.name}`}
                                                                                        className="w-full h-32 mb-8 object-cover"
                                                                                        mixpanel={mixpanel}
                                                                                        eventInfo={{
                                                                                            location:
                                                                                                'Megamenu subcategory',
                                                                                            category: category.name,
                                                                                            subcategory:
                                                                                                subcategory.name,
                                                                                        }}
                                                                                    />
                                                                                </Link>
                                                                                <Link
                                                                                    href={subcategory.href}
                                                                                    className="font-normal text-lg text-gray-900"
                                                                                    variant={ButtonVariant.None}
                                                                                    onClick={() => close()}
                                                                                    mixpanel={{
                                                                                        eventName: `Mega Menu ${subcategory.name} Button`,
                                                                                        properties: {
                                                                                            id: subcategory.id,
                                                                                            name: subcategory.name,
                                                                                        },
                                                                                    }}
                                                                                >
                                                                                    {subcategory.name}
                                                                                </Link>
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                            <Link
                                                                href={category.href}
                                                                className="w-auto sm:inline-flex"
                                                                onClick={() => close()}
                                                                mixpanel={{
                                                                    eventName: `Mega Menu Show all ${category.name} Button`,
                                                                    properties: {
                                                                        id: category.id,
                                                                        name: category.name,
                                                                    },
                                                                }}
                                                                icon
                                                            >
                                                                <span>
                                                                    {t(
                                                                        'mega-menu-category-button',
                                                                        'Show all {{categoryName}}',
                                                                        {
                                                                            categoryName: t(category.name),
                                                                            ns: 'translation',
                                                                        },
                                                                    )}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Popover.Panel>
                                    </>
                                );
                            }}
                        </Popover>
                    ),
                )}

                {navigation.pages &&
                    navigation.pages.map((page) => (
                        <Link
                            key={page.name}
                            href={page.href}
                            variant={ButtonVariant.None}
                            className="flex items-center text-base font-medium text-gray-700 hover:text-gray-800"
                            mixpanel={{
                                eventName: `Mega Menu ${page.name} Button`,
                                properties: {
                                    name: page.name,
                                    id: page.id,
                                },
                            }}
                        >
                            {t(page.name)}
                        </Link>
                    ))}
            </div>
        </Popover.Group>
    );
}
