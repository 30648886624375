'use client';

import { useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Dialog } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import { Button } from '@lib/machine-parts/storefront/ui';
import { isShippableCountry, useTranslation } from '@lib/machine-parts/storefront/utils';

import { useUserGeolocation } from '../../hooks/api/useUserGeolocation';

const TIMEOUT_DEFAULT = 30_000;

export default function LeadGathering({ timeout = TIMEOUT_DEFAULT }: { timeout?: number }) {
    const cookies = useMemo(() => new Cookies(), []);
    const [isVisible, setIsVisible] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const { t } = useTranslation();

    const { data: geolocation } = useUserGeolocation();

    const [message, setMessage] = useState({ contact: '' });

    const handleInputChange = (event: { target: { id: string; value: string } }) =>
        setMessage((prev) => {
            return { ...prev, [event.target.id]: event.target.value };
        });

    const setCookie = (key: string, value: boolean) => {
        const date = new Date();
        date.setMonth(date.getMonth() + 6);
        cookies.set(key, value, { expires: date, secure: true });
    };

    const handleGather = () => {
        setCookie('row-lead-gathered', true);
        onClose();
    };

    const onClose = () => {
        setCookie('missing-parts-popup-dismissed', true);
        setIsVisible(false);
    };

    useEffect(() => {
        if (!geolocation?.country_code) return;
        setTimeout(() => {
            if (!cookies.get('missing-parts-popup-dismissed') && !isShippableCountry(geolocation.country_code)) {
                setIsVisible(true);
            }
        }, timeout);
    }, [geolocation?.country_code, cookies, setIsVisible, timeout]);

    return (
        <>
            <Dialog open={isVisible} onClose={() => onClose()}>
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 px-7" />

                <div
                    data-testid={'lead-gathering-popup-panel'}
                    className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                    <Dialog.Title as="h3" className="text-lg font-heading text-brand-black">
                        {t('lead-gathering-title', 'International Deliveries')}
                    </Dialog.Title>
                    {isSent ? (
                        <div className={'w-full mt-2 flex justify-center items-center space-y-4 flex-col'}>
                            <CheckCircleIcon className={`h-16 text-brand-green`} />
                            <p>{t('lead-gathering-message-sent', 'Message was sent!')}</p>
                        </div>
                    ) : (
                        <div className="mt-2 space-y-4">
                            <p>
                                {t(
                                    'lead-gathering-text',
                                    'We currently only ship to EU countries and Norway. Please enter your e-mail or phone number and we will get in touch as soon as possible.',
                                )}
                            </p>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    {t('lead-gathering-email-label', 'Email or phone number')}
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="email"
                                        id="contact"
                                        className="shadow-sm focus:ring-brand-brown focus:border-brand-brown block w-full sm:text-sm border-brand-black"
                                        placeholder={t(
                                            'lead-gathering-email-input-placeholder',
                                            'you@example.com or +31 6 1234 5678',
                                        )}
                                        aria-describedby="email-description"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mt-4 w-full flex justify-end">
                        {isSent ? (
                            <Button
                                onClick={() => {
                                    setIsSent(false);
                                    handleGather();
                                }}
                                mixpanel={{ eventName: `Close Lead Gathering Modal Button` }}
                            >
                                {t('missing-part-close-button', 'Close')}
                            </Button>
                        ) : (
                            <Button
                                onClick={() => setIsSent(true)}
                                mixpanel={{
                                    eventName: 'ROW Lead Gathering Send Message Button',

                                    properties: {
                                        message: {
                                            ...message,
                                            country: geolocation?.country_name,
                                        },
                                    },
                                }}
                            >
                                {t('missing-part-send-button', 'Send message')}
                            </Button>
                        )}
                    </div>
                </div>
            </Dialog>
        </>
    );
}
