'use client';

import { ReactNode } from 'react';

import { UseCustomerDataResult } from '@lib/machine-parts/storefront/account/data-access';
import { AuthenticationProvider } from '@lib/machine-parts/storefront/account/feature';
import { CartProvider, CheckoutProviderClient } from '@lib/machine-parts/storefront/checkout/data-access';
import { InternationalizationProps, ShopifyStorefrontClientConfig } from '@lib/machine-parts/storefront/utils';
import { ShopifyProvider } from '@shopify/hydrogen-react';

import { CountryCode } from '../../generated/graphql';

import type { LanguageCode } from '@shopify/hydrogen-react/dist/types/storefront-api-types';
import { useUserGeolocation } from '../../hooks/api/useUserGeolocation';

export function LayoutProviders({
    children,
    customer,
    customerAccessToken,
    storefrontConfig,
    ...props
}: {
    children: ReactNode;
    customer: UseCustomerDataResult;
    customerAccessToken?: string;
    storefrontConfig: ShopifyStorefrontClientConfig;
} & InternationalizationProps) {
    const { data: geolocationData } = useUserGeolocation();

    return (
        <ShopifyProvider
            {...storefrontConfig}
            countryIsoCode={props.locale.toUpperCase() as CountryCode}
            languageIsoCode={props.language.toUpperCase() as LanguageCode}
        >
            <AuthenticationProvider
                customer={customer}
                customerAccessToken={customerAccessToken}
                geolocationData={geolocationData}
            >
                <CartProvider countryCode={props.locale} languageCode={props.language}>
                    <CheckoutProviderClient customer={customer}>{children}</CheckoutProviderClient>
                </CartProvider>
            </AuthenticationProvider>
        </ShopifyProvider>
    );
}
