import { i18n } from 'i18next';

const navigationModel: NavigationModel = {
    categories: [
        {
            id: 'maintenance',
            name: 'Maintenance',
            href: '/categories/maintenance',
            image: 'categories/maintenance.webp',
            categoryType: "tag:'pc:maintenance'",
            subcategories: [
                {
                    id: 'maintenance-filters',
                    name: 'Filters',
                    href: '/categories/filters',
                    image: 'categories/maintenance-filters.webp',
                    categoryType: "tag:'pc:filters'",
                    parent: 'maintenance',
                },
                {
                    id: 'maintenance-service-kits',
                    name: 'Service kits',
                    href: '/categories/service-kits',
                    image: 'categories/maintenance-servicekits.webp',
                    categoryType: "tag:'pc:service-kits'",
                    parent: 'maintenance',
                },
                {
                    id: 'maintenance-oils-and-lubrication',
                    name: 'Oils and lubrication',
                    href: '/categories/oils-and-lubrication',
                    image: 'categories/maintenance-oilsandlubrication.webp',
                    categoryType: "tag:'pc:oils-and-lubrication'",
                    parent: 'maintenance',
                },
                {
                    id: 'maintenance-dust-collector-wear-parts',
                    name: 'Dust collector wear parts',
                    href: '/categories/dust-collector-wear-parts',
                    image: 'categories/maintenance-dustcollectorwearparts.webp',
                    categoryType: "tag:'pc:dust-collector-wear-parts'",
                    parent: 'maintenance',
                },
                {
                    id: 'maintenance-belts',
                    name: 'Belts',
                    href: '/categories/belts',
                    image: 'categories/maintenance-belts.webp',
                    categoryType: "tag:'pc:belts'",
                    parent: 'maintenance',
                },
                {
                    id: 'maintenance-seals-hoses-and-rubbers',
                    name: 'Seals, hoses and rubbers',
                    href: '/categories/seals-hoses-and-rubbers',
                    image: 'categories/maintenance-sealshosesandrubbers.webp',
                    categoryType: "tag:'pc:seals-hoses-and-rubbers'",
                    parent: 'maintenance',
                },
            ],
        },
        {
            id: 'wear-parts',
            name: 'Wear parts',
            href: '/categories/wear-parts',
            image: 'categories/wear-parts.webp',
            categoryType: "tag:'pc:wear-parts'",
            subcategories: [
                {
                    id: 'wear-parts-dust-collector-wear-parts',
                    name: 'Dust collector wear parts',
                    href: '/categories/dust-collector-wear-parts',
                    image: 'categories/wearparts-dustcollectorwearparts.webp',
                    categoryType: "tag:'pc:dust-collector-wear-parts'",
                    parent: 'wear-parts',
                },
                {
                    id: 'wear-parts-feed-module-wear-parts',
                    name: 'Feed module wear parts',
                    href: '/categories/feed-module-wear-parts',
                    image: 'categories/wearparts-feedmodulewearparts.webp',
                    categoryType: "tag:'pc:feed-module-wear-parts'",
                    parent: 'wear-parts',
                },
                {
                    id: 'wear-parts-bearings',
                    name: 'Bearings',
                    href: '/categories/bearings',
                    image: 'categories/wearparts-bearings.webp',
                    categoryType: "tag:'pc:bearings'",
                    parent: 'wear-parts',
                },
                {
                    id: 'wear-parts-seals-hoses-and-rubbers',
                    name: 'Seals, hoses and rubbers',
                    href: '/categories/seals-hoses-and-rubbers',
                    image: 'categories/wearparts-sealshosesandrubbers.webp',
                    categoryType: "tag:'pc:seals-hoses-and-rubbers'",
                    parent: 'wear-parts',
                },
            ],
        },
        {
            id: 'powerpack',
            name: 'Powerpack',
            href: '/categories/powerpack',
            image: 'categories/powerpack.webp',
            categoryType: "tag:'pc:powerpack'",
            subcategories: [
                {
                    id: 'powerpack-starters-alternators-and-turbos',
                    name: 'Starters, alternators and turbos',
                    href: '/categories/starters-alternators-and-turbos',
                    image: 'categories/powerpack-startersalternatorsandturbos.webp',
                    categoryType: "tag:'pc:starters-alternators-and-turbos'",
                    parent: 'powerpack',
                },
                {
                    id: 'powerpack-powerpack-filters',
                    name: 'Powerpack filters',
                    href: '/categories/powerpack-filters',
                    image: 'categories/powerpack-powerpackfilters.webp',
                    categoryType: "tag:'pc:powerpack-filters'",
                    parent: 'powerpack',
                },
                {
                    id: 'powerpack-compressors',
                    name: 'Compressors',
                    href: '/categories/compressors',
                    image: 'categories/powerpack-compressors.webp',
                    categoryType: "tag:'pc:compressors'",
                    parent: 'powerpack',
                },
                {
                    id: 'powerpack-engine-oils-and-lubrication',
                    name: 'Engine oils & lubrication',
                    href: '/categories/engine-oils-and-lubrication',
                    image: 'categories/powerpack-engineoilslubrication.webp',
                    categoryType: "tag:'pc:engine-oils-and-lubrication'",
                    parent: 'powerpack',
                },
                {
                    id: 'powerpack-gearboxes-and-gearbox-parts',
                    name: 'Gearboxes & gearbox parts',
                    href: '/categories/gearboxes-and-gearbox-parts',
                    image: 'categories/powerpack-gearboxesgearboxparts.webp',
                    categoryType: "tag:'pc:gearboxes-and-gearbox-parts'",
                    parent: 'powerpack',
                },
            ],
        },
        {
            id: 'hydraulics',
            name: 'Hydraulics',
            href: '/categories/hydraulics',
            image: 'categories/hydraulics.webp',
            categoryType: "tag:'pc:hydraulics'",
            subcategories: [
                {
                    id: 'hydraulics-cylinders',
                    name: 'Cylinders',
                    href: '/categories/cylinders',
                    image: 'categories/hydraulics-cylinders.webp',
                    categoryType: "tag:'pc:cylinders'",
                    parent: 'hydraulics',
                },
                {
                    id: 'hydraulics-hydraulic-pumps-and-motors',
                    name: 'Hydraulic pumps and motors',
                    href: '/categories/hydraulic-pumps-and-motors',
                    image: 'categories/hydraulics-hydraulicpumpsandmotors.webp',
                    categoryType: "tag:'pc:hydraulic-pumps-and-motors'",
                    parent: 'hydraulics',
                },
                {
                    id: 'hydraulics-hydraulic-oils-and-lubrication',
                    name: 'Hydraulic oils & lubrication',
                    href: '/categories/hydraulic-oils-and-lubrication',
                    image: 'categories/hydraulics-hydraulicoilslubrication.webp',
                    categoryType: "tag:'pc:hydraulic-oils-and-lubrication'",
                    parent: 'hydraulics',
                },
                {
                    id: 'hydraulics-hydraulic-filters',
                    name: 'Hydraulic filters',
                    href: '/categories/hydraulic-filters',
                    image: 'categories/hydraulics-hydraulicfilters.webp',
                    categoryType: "tag:'pc:hydraulic-filters'",
                    parent: 'hydraulics',
                },
            ],
        },
        {
            id: 'undercarriage',
            name: 'Undercarriage',
            href: '/categories/undercarriage',
            image: 'categories/undercarriage.webp',
            categoryType: "tag:'pc:undercarriage'",
            subcategories: [
                {
                    id: 'undercarriage-undercarriage-tracks-and-rollers',
                    name: 'Undercarriage tracks & rollers',
                    href: '/categories/undercarriage-tracks-and-rollers',
                    image: 'categories/undercarriage-undercarriagetracksrollers.webp',
                    categoryType: "tag:'pc:undercarriage-tracks-and-rollers'",
                    parent: 'undercarriage',
                },
                {
                    id: 'undercarriage-gearboxes-and-gearbox-parts',
                    name: 'Gearboxes & gearbox parts',
                    href: '/categories/gearboxes-and-gearbox-parts',
                    image: 'categories/undercarriage-gearboxesgearboxparts.webp',
                    categoryType: "tag:'pc:gearboxes-and-gearbox-parts'",
                    parent: 'undercarriage',
                },
                {
                    id: 'undercarriage-undercarriage-chains',
                    name: 'Undercarriage chains',
                    href: '/categories/undercarriage-chains',
                    image: 'categories/undercarriage-undercarriagechains.webp',
                    categoryType: "tag:'pc:undercarriage-chains'",
                    parent: 'undercarriage',
                },
            ],
        },
        {
            id: 'boom-and-feeds',
            name: 'Boom & feeds',
            href: '/categories/boom-and-feeds',
            image: 'categories/boom-and-feeds.webp',
            categoryType: "tag:'pc:boom-and-feeds'",
            subcategories: [
                {
                    id: 'boom-and-feeds-rock-drills',
                    name: 'Rock drills',
                    href: '/categories/rock-drills',
                    image: 'categories/boomfeeds-rockdrills.webp',
                    categoryType: "tag:'pc:rock-drills'",
                    parent: 'boom-and-feeds',
                },
                {
                    id: 'boom-and-feeds-feed-motors-and-boom-parts',
                    name: 'Feed motors & boom parts',
                    href: '/categories/feed-motors-and-boom-parts',
                    image: 'categories/boomfeeds-feedmotorsboomparts.webp',
                    categoryType: "tag:'pc:feed-motors-and-boom-parts'",
                    parent: 'boom-and-feeds',
                },
                {
                    id: 'boom-and-feeds-feed-chains-and-motors',
                    name: 'Feed chains & motors',
                    href: '/categories/feed-chains-and-motors',
                    image: 'categories/boomfeeds-feedchainsmotors.webp',
                    categoryType: "tag:'pc:feed-chains-and-motors'",
                    parent: 'boom-and-feeds',
                },
            ],
        },
        {
            id: 'other',
            name: 'Other',
            href: '/categories/other',
            image: 'categories/other.webp',
            categoryType: "tag:'pc:other'",
            subcategories: [
                {
                    id: 'other-cabin-air-conditioning',
                    name: 'Cabin air conditioning',
                    href: '/categories/cabin-air-conditioning',
                    image: 'categories/other-cabinairconditioning.webp',
                    categoryType: "tag:'pc:cabin-air-conditioning'",
                    parent: 'other',
                },
                {
                    id: 'other-electrical-parts',
                    name: 'Electrical parts',
                    href: '/categories/electrical-parts',
                    image: 'categories/other-electricalparts.webp',
                    categoryType: "tag:'pc:electrical-parts'",
                    parent: 'other',
                },
                {
                    id: 'other-sensors-and-indicators',
                    name: 'Sensors & indicators',
                    href: '/categories/sensors-and-indicators',
                    image: 'categories/other-sensorsindicators.webp',
                    categoryType: "tag:'pc:sensors-and-indicators'",
                    parent: 'other',
                },
                {
                    id: 'other-cabin-parts',
                    name: 'Cabin parts',
                    href: '/categories/cabin-parts',
                    image: 'categories/other-cabinparts.webp',
                    categoryType: "tag:'pc:cabin-parts'",
                    parent: 'other',
                },
                {
                    id: 'other-cables-and-harnesses',
                    name: 'Cables & harnesses',
                    href: '/categories/cables-and-harnesses',
                    image: 'categories/other-cablesharnesses.webp',
                    categoryType: "tag:'pc:cables-and-harnesses'",
                    parent: 'other',
                },
                {
                    id: 'other-monitors',
                    name: 'Monitors',
                    href: '/categories/monitors',
                    image: 'categories/other-monitors.webp',
                    categoryType: "tag:'pc:monitors'",
                    parent: 'other',
                },
                {
                    id: 'other-screws-and-fasteners',
                    name: 'Screws & fasteners',
                    href: '/categories/screws-and-fasteners',
                    image: 'categories/other-screwsfasteners.webp',
                    categoryType: "tag:'pc:screws-and-fasteners'",
                    parent: 'other',
                },
                {
                    id: 'other-other',
                    name: 'Other',
                    href: '/categories/other',
                    image: 'categories/maintenance-servicekits.webp',
                    categoryType: "tag:'pc:other'",
                    parent: 'other',
                },
            ],
        },
        {
            id: 'all-products',
            name: 'All products',
            href: '/categories/all-products',
            image: 'categories/all-products.webp',
            categoryType: '*',
        },
        {
            id: 'used-machines',
            name: 'Used machines',
            href: '/categories/used-machines',
            image: 'categories/all-products.webp',
            categoryType: 'used-machines',
        },
    ],
};

const dcImageSize = { width: 456, height: 294 };
const dxImageSize = { width: 640, height: 302 };

const coordinates = (dot: { x: number; y: number }, imageSize: { width: number; height: number }) => {
    return {
        x: (dot.x / imageSize.width) * 100 + '%',
        y: (dot.y / imageSize.height) * 100 + '%',
    };
};

export const categoryDots = {
    DC: [
        {
            categoryId: 'powerpack',
            ...coordinates({ x: 42, y: 188 }, dcImageSize),
        },
        {
            categoryId: 'undercarriage',
            ...coordinates({ x: 138, y: 237 }, dcImageSize),
        },
        {
            categoryId: 'hydraulics',
            ...coordinates({ x: 185, y: 178 }, dcImageSize),
        },
        {
            categoryId: 'boom-and-feeds',
            ...coordinates({ x: 358, y: 147 }, dcImageSize),
        },
    ],
    DX: [
        {
            categoryId: 'powerpack',
            ...coordinates({ x: 71, y: 128 }, dxImageSize),
        },
        {
            categoryId: 'undercarriage',
            ...coordinates({ x: 211, y: 190 }, dxImageSize),
        },
        {
            categoryId: 'hydraulics',
            ...coordinates({ x: 320, y: 132 }, dxImageSize),
        },
        {
            categoryId: 'boom-and-feeds',
            ...coordinates({ x: 433, y: 158 }, dxImageSize),
        },
    ],
};

export interface NavigationModel {
    categories: Category[];
    pages?: Category[];
}

export interface Category {
    id: string;
    name: string;
    href: string;
    categoryType?: string;
    image?: string;
    subcategories?: Category[];
    parent?: string;
}

export interface FooterModel {
    quickLinks: Link[];
    menu: FooterMenu[];
}

export interface Link {
    name: string;
    href: string;
    eventName: string;
}

export interface FooterMenu {
    name: string;
    menuItems: Link[];
}

export const navigation = navigationModel;

export const footer = (i18next: i18n): FooterModel => ({
    quickLinks: [
        {
            name: i18next.t('footer-all-products-link', 'ALL PRODUCTS'),
            href: '/categories/all-products',
            eventName: 'ALL PRODUCTS',
        },
        { name: i18next.t('footer-contact-link', 'CONTACT'), href: '/contact', eventName: 'CONTACT' },
        { name: i18next.t('footer-faq-link', 'FAQ'), href: '/faq', eventName: 'FAQ' },
    ],
    menu: [
        {
            name: i18next.t('footer-categories-name', 'CATEGORIES'),
            menuItems: navigation.categories.map((category) => ({
                name: i18next.t(category.name, { ns: 'categories' }),
                href: category.href,
                eventName: category.name,
            })),
        },
        {
            name: i18next.t('footer-service-name', 'SERVICE'),
            menuItems: [
                { name: i18next.t('footer-faq-link', 'FAQ'), href: '/faq', eventName: 'FAQ' },
                {
                    name: i18next.t('footer-delivery-payment-link', 'Delivery & Payment'),
                    href: '/delivery-and-payment',
                    eventName: 'Delivery & Payment',
                },
            ],
        },
        {
            name: i18next.t('footer-about-name', 'ABOUT'),
            menuItems: [
                { name: i18next.t('footer-about-us-link', 'About Us'), href: '/about-us', eventName: 'About Us' },
                {
                    name: i18next.t('footer-company-details-link', 'Company Details'),
                    href: '/company-details',
                    eventName: 'Company Details',
                },
                {
                    name: i18next.t('footer-legal-notice-link', 'Legal Notice'),
                    href: '/legal-notice',
                    eventName: 'Legal Notice',
                },
            ],
        },
    ],
});
