'use client';

import { useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import { InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import { Button, ButtonVariant } from '@lib/machine-parts/storefront/ui';
import { Link } from '@lib/machine-parts/storefront/ui/nextjs';

import { useTranslation } from '@lib/machine-parts/storefront/utils';

export default function CookieConsent() {
    const cookies = useMemo(() => new Cookies(), []);
    const [isVisible, setIsVisible] = useState(false);
    const { t } = useTranslation();

    const handleConsent = () => {
        const date = new Date();
        date.setMonth(date.getMonth() + 6);
        cookies.set('cookie-consent', true, { expires: date, secure: true });
        setIsVisible(false);
    };

    useEffect(() => {
        setIsVisible(!cookies.get('cookie-consent'));
    }, [cookies]);

    return (
        <>
            <div className={`fixed inset-x-0 bottom-0 ${isVisible ? 'block' : 'hidden'} z-20`}>
                <div className="bg-brand-brown shadow">
                    <div className="mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between flex-wrap">
                            <div className="w-0 flex-1 flex items-center">
                                <p className="ml-3 font-medium text-white   ">
                                    <InformationCircleIcon className="h-6 w-6 mr-4 text-white inline" />
                                    <span className="inline">
                                        {t(
                                            'cookie-consent-warning',
                                            'This website uses cookies to ensure you get the best experience on our website.',
                                        )}
                                    </span>
                                </p>
                            </div>
                            <div className="order-3 mt-2 flex flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                                <Link
                                    href="/privacy-and-cookie-policy"
                                    icon
                                    mixpanel={{ eventName: `Cookie Privacy Policy Button` }}
                                >
                                    <span>{t('cookie-consent-learn-more-button', 'Learn more')}</span>
                                </Link>
                                <Button
                                    className="!text-white"
                                    icon={{ className: `!fill-white` }}
                                    onClick={handleConsent}
                                    mixpanel={{ eventName: `Cookie Understand Button` }}
                                >
                                    {t('cookie-consent-i-understand-button', 'I Understand')}
                                </Button>
                            </div>
                            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                                <Button
                                    onClick={() => setIsVisible(false)}
                                    className="-mr-1 flex p-2 sm:-mr-2"
                                    variant={ButtonVariant.Tertiary}
                                    mixpanel={{ eventName: `Close Cookie Consent Banner Button` }}
                                >
                                    <span className="sr-only">{t('cookie-consent-dismiss-button', 'Dismiss')}</span>
                                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
