'use client';

import { usePathname, useRouter } from 'next/navigation';

import { useCartUI } from '@lib/machine-parts/storefront/checkout/data-access';
import { MixpanelTrack } from '@lib/machine-parts/storefront/utils';

import { CartIconWithItems } from './CartIconWithItems';

type Props = {
    handleClick: () => void;
    className: string;
    cartIconSize?: string;
    navigateToCart?: boolean;
};

/**
 * A client component that defines the behavior when a user toggles a cart
 */
export function CartToggle({ handleClick, className, cartIconSize, navigateToCart = false }: Props) {
    const cartUI = useCartUI();
    const pathname = usePathname();
    const navigate = useRouter();

    if (cartUI == null) {
        throw new Error('CartToggle must be a descendent of a CartUIProvider');
    }

    const { isCartOpen, toggleCart } = cartUI;

    return (
        <MixpanelTrack eventName="Cart Toggle Button" properties={{ page: pathname }}>
            <button
                type="button"
                aria-expanded={isCartOpen}
                aria-controls="cart"
                onClick={() => {
                    navigateToCart ? navigate.push('/cart') : toggleCart();
                    handleClick();
                }}
                onDoubleClick={() => navigate.push('/cart')}
                className={className}
                data-testid="cart-button"
            >
                <CartIconWithItems cartIconSize={cartIconSize} />
                <span className="sr-only">Open cart</span>
            </button>
        </MixpanelTrack>
    );
}
