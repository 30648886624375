'use client';

import { useTranslation } from 'react-i18next';
import { CartLineProvider, useCart } from '@shopify/hydrogen-react';
import { CartLine } from '@lib/machine-parts/storefront/checkout/feature';
import { CartSanityData, useCartUI } from '@lib/machine-parts/storefront/checkout/data-access';
import { DesignType } from '@lib/machine-parts/storefront/checkout/ui';
import { formatTranslation } from '@lib/machine-parts/storefront/utils';

export function CartItems({ cartTranslation }: { cartTranslation?: CartSanityData }) {
    const { t } = useTranslation();
    const cartT = formatTranslation(cartTranslation);
    const { lines } = useCart();
    const { closeCart } = useCartUI();

    return (
        <div className="px-7 flex-grow" role="table" aria-label="Shopping cart">
            <div role="row" className="sr-only">
                <div role="columnheader">{t('cart-product-image', 'Product image')}</div>
                <div role="columnheader">{t('cart-product-details', 'Product details')}</div>
                <div role="columnheader">{t('cart-product-price', 'Price')}</div>
            </div>
            {lines?.map((line, index) =>
                line ? (
                    <CartLineProvider key={index} line={line}>
                        <CartLine
                            designType={DesignType.cart_sidebar}
                            onCartNavigate={() => closeCart()}
                            itemNoFormatter={(sku: string) =>
                                cartT('itemNo', {
                                    allowUndefined: true,
                                    context: { sku },
                                })
                            }
                            unitFormatter={(price: string) =>
                                cartT('unitProductPriceFormat', {
                                    allowUndefined: true,
                                    context: { price },
                                })
                            }
                            totalFormatter={(price: string) =>
                                cartT('totalProductPriceFormat', {
                                    allowUndefined: true,
                                    context: { price },
                                })
                            }
                        />
                    </CartLineProvider>
                ) : null,
            )}
        </div>
    );
}
