'use client';

import { CartIcon } from '@lib/machine-parts/storefront/ui';
import { useCart } from '@shopify/hydrogen-react';

export function CartIconWithItems({ cartIconSize }: { cartIconSize?: string }) {
    const { totalQuantity = 0 } = useCart();

    return (
        <>
            <div className="relative text-brand-black">
                <CartIcon cartIconSize={cartIconSize} />

                <div
                    className={`bg-brand-brown text-xs rounded-full leading-none text-white absolute bottom-2 right-0 flex items-center justify-center transform translate-y-1/2 transition-all ${
                        totalQuantity > 0 ? 'h-4 w-4' : 'h-0 w-0 overflow-hidden'
                    }`}
                    aria-hidden
                    data-testid="cart-amount"
                >
                    {totalQuantity > 0 ? totalQuantity : null}
                </div>
            </div>
            <span className="sr-only">Cart, {totalQuantity} items</span>
        </>
    );
}
